import { useLottie } from 'lottie-react';
import React from 'react';

const MentalSpinnerView = ({
  dimension, mode,
}: { dimension: number, mode: 'light' | 'dark' }) => {
  const animationData = (() => {
    if (mode === 'light') return require('../../../../public/lottie/LoadingSpinnerLight.json');
    return require('../../../../public/lottie/LoadingSpinnerDark.json');
  })();

  const { View } = useLottie({
    animationData,
    loop: true,
    style: {
      width: dimension,
      height: dimension,
    },
  });

  return (
    <>
      {View}
    </>
  );
};

export default MentalSpinnerView;
