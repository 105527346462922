import React from 'react';
import * as Sentry from '@sentry/nextjs';
import ErrorScreen from 'components/error/ErrorScreen';

const withErrorBoundary = (Component: React.ComponentType) => {
  return function WrappedComponent(props: any) {
    return (
      <Sentry.ErrorBoundary fallback={() => <ErrorScreen />}>
        <Component {...props} />
      </Sentry.ErrorBoundary>
    );
  };
};

export default withErrorBoundary;
