import React from 'react';

const MentalLettermark = ({ height = 43.0 }: { height?: number }) => {
  const width = (height / 43.0) * 174.0;
  return (
    <svg width={width} height={height}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M162.233 35.4276V13.4221C162.233 13.1727 162.027 12.9705 161.774 12.9705H153.925C153.672 12.9705 153.467 13.1727 153.467 13.4221V42.5746C153.467 42.7544 153.615 42.9004 153.797 42.9004H173.669C173.852 42.9004 174 42.7544 174 42.5746V35.7533C174 35.5735 173.852 35.4276 173.669 35.4276H162.233Z" fill="#4EE69F" />
        <path d="M131.59 26.2836V21.2067C131.59 21.1123 131.667 21.0357 131.763 21.0357H140.599C140.694 21.0357 140.771 21.1116 140.771 21.2051V26.2836C140.771 26.3735 140.697 26.4468 140.606 26.4468H131.755C131.664 26.4468 131.59 26.3735 131.59 26.2836ZM122.824 13.3473V42.5759C122.824 42.755 122.971 42.9004 123.153 42.9004H131.26C131.442 42.9004 131.59 42.755 131.59 42.5759V33.2647H140.771V42.5759C140.771 42.755 140.918 42.9004 141.1 42.9004H149.208C149.39 42.9004 149.537 42.755 149.537 42.5759V13.3473C149.537 13.1391 149.366 12.9702 149.154 12.9702H123.206C122.995 12.9702 122.824 13.1391 122.824 13.3473Z" fill="#4EE69F" />
        <path d="M93.9189 13.3286V20.0884C93.9189 20.2851 94.0811 20.4449 94.2808 20.4449H102.688V42.5981C102.688 42.7651 102.825 42.9004 102.994 42.9004H111.147C111.316 42.9004 111.454 42.7651 111.454 42.5981V20.4449H119.861C120.061 20.4449 120.222 20.2851 120.222 20.0884V13.3286C120.222 13.1316 120.061 12.9721 119.861 12.9721H94.2808C94.0811 12.9721 93.9189 13.1316 93.9189 13.3286Z" fill="#4EE69F" />
        <path d="M82.4439 28.3527L73.4523 13.2885C73.3346 13.0914 73.1201 12.9705 72.8881 12.9705H64.9237C64.6878 12.9705 64.4966 13.1589 64.4966 13.3914V42.5363C64.4966 42.7372 64.6619 42.9004 64.8661 42.9004H72.8929C73.0971 42.9004 73.2624 42.7372 73.2624 42.5363V27.6563L82.2228 42.5332C82.3598 42.761 82.6087 42.9004 82.8774 42.9004H90.7035C90.9378 42.9004 91.128 42.7135 91.1285 42.4826L91.2087 13.3948C91.2095 13.1605 91.0169 12.9705 90.7794 12.9705H82.9179C82.6561 12.9705 82.4439 13.1795 82.4439 13.4375V28.3527Z" fill="#4EE69F" />
        <path d="M38.928 12.9709V42.6428C38.928 42.9183 39.2316 43.0898 39.4728 42.9506L60.9797 30.5387C61.136 30.4501 61.232 30.2862 61.232 30.1087V22.3481C61.232 22.1967 61.0657 22.1018 60.9326 22.1774L47.694 29.9036V26.4498L59.0402 19.8652C59.1285 19.8149 59.1833 19.7218 59.1833 19.6212V12.5393C59.1833 12.3499 58.9757 12.2315 58.809 12.3259L47.694 18.7797V15.4471L61.0146 7.67456C61.1493 7.59819 61.232 7.45668 61.232 7.30369V0.258282C61.232 0.059948 61.0144 -0.0641098 60.8401 0.0346642L39.1829 12.5365C39.025 12.6261 38.928 12.7916 38.928 12.9709Z" fill="#4EE69F" />
        <path d="M0 13.376V42.6439C0 42.7857 0.116641 42.9004 0.26026 42.9004H8.54044C8.66502 42.9004 8.76579 42.8011 8.76579 42.6783V21.6096H13.1508V42.6439C13.1508 42.7857 13.2672 42.9004 13.4111 42.9004H21.6563C21.7999 42.9004 21.9166 42.7857 21.9166 42.6439V21.6096H26.3032V42.6783C26.3032 42.8011 26.4042 42.9004 26.5288 42.9004H34.809C34.9526 42.9004 35.0692 42.7857 35.0692 42.6439L35.0713 13.3776C35.0713 13.1534 34.8873 12.9721 34.6601 12.9721L0.411549 12.9705C0.184351 12.9705 0 13.1521 0 13.376Z" fill="#4EE69F" />
      </svg>
    </svg>
  );
};
export default MentalLettermark;
