import React from 'react';

const Fonts = () => {
  return (
    <>
      <link rel="preload" href="/fonts/Nexa/Nexa-Bold.otf" as="font" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/GTAmerica/GT-America-Compressed-Bold.otf" as="font" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/ChivoMono/ChivoMono-Bold.ttf" as="font" crossOrigin="anonymous" />
    </>
  );
};

export default Fonts;
