import * as t from 'io-ts';
import * as D from 'io-ts/Decoder';

export type JSONPurePrimitive =
    | string
    | number
    | boolean;

export type JSONPrimitive =
    | JSONPurePrimitive
    | Date;

export type JSONValue =
    | JSONPrimitive
    | JSONObject
    | JSONArray;

export interface JSONObject {
    [x: string]: JSONValue,
}

export interface JSONObjectFlat {
    [x: string]: JSONPrimitive | undefined,
}

export type JSONArray = Array<JSONValue>;

export const tJSONPrimitive = t.union([t.string, t.number, t.boolean]); // doesn't include dates
export const tJSONObject = t.record(t.string, tJSONPrimitive);

export const dJSONPrimitive = D.union(D.string, D.number, D.boolean); // doesn't include dates
export const dJSONObject = D.record(dJSONPrimitive);
