import * as Cookies from 'js-cookie';
import { DateTime } from 'luxon';

const prefix = 'mental_';

// Chrome is setting this to only expire in 400 days, but that's the max we can get
const defaultExpires = DateTime.now().plus({ years: 10 }).toJSDate();

export const set = (key: string, value: any, options?: Cookies.CookieAttributes) => {
  Cookies.set(prefix + key, value, {
    ...options,
    expires: options?.expires ?? defaultExpires,
  });
};

export const get = (key: string) => {
  return Cookies.get(prefix + key);
};

export const getJSON = <T>(key: string) => {
  const stringResponse = get(key);
  if (!stringResponse) return undefined;

  try {
    return JSON.parse(stringResponse) as T;
  } catch (e) {
    return undefined;
  }
};

export const setJSON = <T>(key: string, value: T, options?: Cookies.CookieAttributes) => {
  set(key, JSON.stringify(value), {
    ...options,
    expires: options?.expires ?? defaultExpires,
  });
};

export const remove = (key: string) => {
  Cookies.remove(prefix + key);
};

export const removeAll = () => {
  const cookies = Cookies.get();
  for (const key in cookies) {
    if (key.startsWith(prefix)) {
      Cookies.remove(key);
    }
  }
};
