const rawGrid = {
  HairlineThin: 1,
  Hairline: 2,
  XXXSmall: 4,
  XXSmall: 8,
  XSmall: 16,
  Small: 24,
  Medium: 32,
  MediumPlus: 40,
  Large: 48,
  LargePlus: 56,
  XLarge: 64,
  XXLarge: 80,
  XXXLarge: 96,
  XXXXLarge: 196,

  Images: {
    XXSmall: 32,
    XSmall: 48,
    Small: 64,
    Medium: 92,
    MediumPlus: 100,
    Large: 120,
    LargePlus: 134,
    XLarge: 196,
  },
};

const grid = {
  ...rawGrid,

  /* Semantic names for grid points */
  GuttersX: rawGrid.XXXLarge,
  GuttersY: rawGrid.Large,
  ProgressBarHeight: rawGrid.XXXSmall,

  PrimaryButtonHeight: rawGrid.Large,

  StatusBarHeight: 22,

  PageDimensions: {
    StandardMenuWidth: 400,
    InnerContent: 830,
    InnerContentLarge: 1200,
  },

  Responsive: {
    MentalButton: {
      Large: ({ isMobile }: { isMobile: boolean }) => {
        return isMobile ? 300 : 360;
      },
      XLarge: ({ isMobile }: { isMobile: boolean }) => {
        return isMobile ? 360 : 380;
      },
    },
    GuttersX: ({ isMobile }: { isMobile: boolean }) => {
      return isMobile ? grid.Medium : grid.XXLarge;
    },
  },

  // TODO: Avoid using these entirely
  Custom: {
    d6: 6,
    d10: 10,
    d12: 12,
    d20: 20,
    d36: 36,
    d44: 44,
    d56: 56,
    d112: 112,
    d128: 128,
  },

  /* Custom off-grid dimensions */
  // TODO: Work with design to eliminate all instances of hard-coded values that don't
  // align with the grid.
  LargeIconWidth: 36,
};
export default grid;
